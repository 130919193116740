import {
  BaseHTMLAttributes,
  ForwardedRef,
  forwardRef,
  memo,
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import {
  unstable_Combobox as Combobox,
  unstable_ComboboxOption as ComboboxOption,
  unstable_ComboboxPopover as ComboboxPopover,
  unstable_useComboboxState as useComboboxState,
} from "reakit/Combobox"
import { FieldVariantsPropsType } from "../../../types/types"
import { Button } from "../Button/Button"
import { Placeholder, StyledFieldWrapper } from "../Field/StyledField"
import { Icon, IconNameType } from "../Icon"
import { ComponentLoader } from "../Loaders/ComponentLoader/ComponentLoader"
import { ErrorMessageField, Typography } from "../Typography/Typography"
import {
  SelectedHint,
  SelectedHintBody,
  SelectedIcon,
  StyledButtonContainer,
  StyledIconSelect,
  StyledOptionInner,
  StyledSelect,
  StyledSelectedTitle,
  StyledSelectInputDiv,
  StyledSelectInputWrapper,
} from "./StyledSelect"

type VariantProps = "small" | "default"
interface SelectItemsType {
  icon?: IconNameType
  value: string
  name: string
  layout?: ReactNode
  disabled?: boolean
}
interface SelectPropsType {
  items: SelectItemsType[]
  baseId?: string
  ariaLabel?: string
  isIcon?: boolean
  variant: VariantProps
  onSelectValue?: (value: string) => void
  stateSelect?: string
  isFetching?: boolean
  buttonAddedItem?: ReactNode
  iconSelect?: IconNameType
  staticPlaceholder?: string
  isVisibleLayout?: boolean
  isVisibleSelectedHint?: boolean
  required?: boolean
}

const Select = memo(
  forwardRef<
    ForwardedRef<HTMLInputElement>,
    SelectPropsType & {
      initialValue?: string
    } & BaseHTMLAttributes<unknown> &
      Pick<
        FieldVariantsPropsType,
        "withButton" | "onClickButton" | "iconButton" | "errorMessage"
      >
  >(
    (
      {
        items,
        ariaLabel = "",
        baseId,
        variant,
        isIcon = false,
        onSelectValue,
        initialValue,
        isFetching,
        errorMessage,
        buttonAddedItem,
        withButton,
        onClickButton,
        iconButton,
        iconSelect,
        staticPlaceholder,
        isVisibleLayout = false,
        isVisibleSelectedHint = false,
        required = false,
        ...props
      },
      ref,
    ) => {
      const [itemSelected, setItemSelected] = useState<
        SelectItemsType | undefined
      >()

      const itemsMemo = useMemo(() => items, [items])

      const combobox = useComboboxState({
        list: true,
        inline: true,
        autoSelect: true,
        gutter: 2,
        values: (itemsMemo || []).map((item) => item.value),
        unstable_offset: [0, -2],
        placement: "bottom",
        limit: false,
        shift: false,
      })

      const onSelectHandle = useCallback(
        (value: string) => {
          combobox.hide()
          combobox.setInputValue("")
          const item = itemsMemo.find((item) => item.value === value)
          setItemSelected(item)
          if (item?.value !== undefined && item.value.length > 0) {
            if (onSelectValue) {
              onSelectValue(item.value)
            }
          }
        },
        [combobox, itemsMemo, onSelectValue],
      )

      const isEmpty = useMemo(
        () => (itemSelected?.value || "").length <= 0,
        [itemSelected?.value],
      )

      useEffect(() => {
        combobox.setValues(itemsMemo.map((item) => item.value))
      }, [itemsMemo])

      useEffect(() => {
        if (!!initialValue && initialValue.length > 0) {
          setItemSelected(itemsMemo.find((i) => i.value === initialValue))
        }
      }, [initialValue, itemsMemo])

      return (
        <StyledFieldWrapper
          data-iswith-button={withButton}
          data-iserror={!!errorMessage}
          data-required={required}
        >
          <StyledSelect data-variant={variant} {...props}>
            {isFetching && <ComponentLoader />}
            <StyledSelectInputWrapper>
              <StyledSelectInputDiv data-is-empty={isEmpty}>
                {!!iconSelect && (
                  <StyledIconSelect>
                    <Icon NameIcon={iconSelect} />
                  </StyledIconSelect>
                )}
                <Combobox
                  {...combobox}
                  baseId={baseId}
                  aria-label={ariaLabel || "select"}
                  readOnly={true}
                  onClick={(e) => {
                    if (combobox.visible) {
                      e.preventDefault()
                      combobox.setVisible(false)
                    }
                  }}
                  ref={ref as ForwardedRef<HTMLInputElement>}
                />

                {variant !== "small" && ariaLabel && (
                  <Placeholder htmlFor={baseId}>{ariaLabel}</Placeholder>
                )}
                <StyledSelectedTitle>
                  {!!staticPlaceholder && staticPlaceholder}{" "}
                  {isVisibleLayout && itemSelected?.layout
                    ? itemSelected?.layout
                    : itemSelected?.name}
                </StyledSelectedTitle>
                {isIcon && !!itemSelected?.icon && (
                  <SelectedIcon>
                    <Icon NameIcon={itemSelected.icon} />
                  </SelectedIcon>
                )}
                <Icon NameIcon={"AngleBottom"} />
              </StyledSelectInputDiv>
              {withButton && (
                <Button
                  variant={"box"}
                  icon={iconButton}
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    e.preventDefault()
                    if (onClickButton) {
                      onClickButton(e)
                    }
                  }}
                />
              )}
            </StyledSelectInputWrapper>

            <ComboboxPopover {...combobox} aria-label={ariaLabel}>
              {!!buttonAddedItem && (
                <StyledButtonContainer>{buttonAddedItem}</StyledButtonContainer>
              )}
              {combobox.matches.map((value) => {
                const item = items.find((item) => item.value === value)
                return (
                  <ComboboxOption
                    {...combobox}
                    key={value}
                    value={value}
                    disabled={item?.disabled}
                    onClick={(event) => {
                      event.preventDefault()
                      onSelectHandle(value)
                    }}
                  >
                    <StyledOptionInner>
                      {item?.layout !== undefined ? item?.layout : item?.name}
                      {isIcon && item?.icon && <Icon NameIcon={item.icon} />}
                    </StyledOptionInner>
                    {itemSelected?.value === value && (
                      <Icon NameIcon={"Check"} />
                    )}
                  </ComboboxOption>
                )
              })}
            </ComboboxPopover>
          </StyledSelect>

          {isVisibleSelectedHint && itemSelected !== undefined && (
            <SelectedHint>
              <Typography variant={"p12"}>
                <b>Выбран: </b>
              </Typography>
              <SelectedHintBody>
                {itemSelected?.layout !== undefined
                  ? itemSelected?.layout
                  : itemSelected?.name}
              </SelectedHintBody>
            </SelectedHint>
          )}

          {errorMessage && (
            <ErrorMessageField>{errorMessage}</ErrorMessageField>
          )}
        </StyledFieldWrapper>
      )
    },
  ),
)

Select.displayName = "Select"

export type { SelectItemsType }
export { Select }
