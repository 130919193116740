import { FC, memo, useState } from "react"
import { useQuery } from "react-query"
import { fetchCatalog } from "../../../api/catalogAPI"
import { Container } from "../../../styles/utils/StyledGrid"
import { SectionProducts, SectionTitle } from "../../../styles/utils/Utils"
import { CatalogResponseType } from "../../../types/types"
import { VIEW_PRODUCTS_GRID } from "../../../utils/constants"
import { ProductsGrid } from "../../Products/Grid"
import { StyledTileProducts } from "../../Products/StyledProductsGrid"

export type NewPropsType = {
  initial?: CatalogResponseType
}

export const New: FC<NewPropsType> = memo(({ initial }) => {
  const [products, setProducts] = useState([...(initial?.products || [])])

  useQuery(
    ["new"],
    () =>
      fetchCatalog({
        isEnabled: "1",
        perPage: 20,
        isNew: true,
      }),
    {
      staleTime: Infinity,
      initialData: initial,
      onSuccess: (data) => {
        console.log("onSuccess ", data)
        if (data !== null) {
          setProducts([...(data.products || [])])
        }
      },
    },
  )

  if (!(products.length > 0)) {
    return <></>
  }

  return (
    <SectionProducts>
      <Container>
        <SectionTitle>Новинки</SectionTitle>
        <ProductsGrid
          as={StyledTileProducts}
          products={products}
          view={VIEW_PRODUCTS_GRID}
        />
      </Container>
    </SectionProducts>
  )
})

New.displayName = "New"
